type Translations = Record<string, Record<string, string>>;

const translations: Translations = {
  en: {
    subscriptionRequired:
      "Your account does not currently have a subscription or pass that enables you to watch this video.",
    home: "Home",
    leagues: "Leagues",
    chl: "CHL",
    ohl: "OHL",
    whl: "WHL",
    qmjhl: "QMJHL",
    schedule: "Schedule",
    subscribe: "Subscribe Now",
    settings: "Settings",
    search: "Search",
    signin: "Login / Register",
    signout: "Sign out",
    account: "Account",
    myAccount: "My Account",
    updateAccount: "Update Account",
    changePassword: "Change Password",

    language: "Language",
    languageText: "Choose Language",

    about: "About Us",
    aboutText:
      "The Canadian Hockey League is the world’s largest development hockey league with 52 Canadian and eight American teams participating in the Ontario Hockey League, Quebec Major Junior Hockey League and Western Hockey League. CHL players graduate from high school at a rate higher than the Canadian national average. Last season, more than nine million fans attended CHL games in the regular season, playoffs and at the Memorial Cup Presented by Kia. The CHL supplies more players to the National Hockey League and U SPORTS than any other league.",

    support: "CHL TV Support",
    supportText:
      "For information and frequently asked questions please visit our help centre at:",

    pp: "Privacy Policy",
    tc: "Terms & Conditions",

    selectLeague: "SELECT YOUR LEAGUE",
    audioTrack: "Audio Track:",
  },
  fr: {
    subscriptionRequired:
      "Votre compte ne dispose pas actuellement d’un abonnement ou d’un pass vous permettant de regarder cette vidéo.",
    home: "Accueil",
    leagues: "Ligues",
    chl: "CHL",
    ohl: "OHL",
    whl: "WHL",
    qmjhl: "LHJMQ",
    schedule: "Horaire",
    subscribe: "Abonnez-vous maintenant",
    settings: "Paramètres",
    search: "Rechercher",
    signin: "Se connecter",
    signout: "Se déconnecter",
    account: "Compte",
    myAccount: "Mon compte",
    updateAccount: "Modifier mon compte",
    changePassword: "Modifier mon mot de passe",

    language: "Langue",
    languageText: "Choisir la langue",

    about: "À propos de nous",
    aboutText:
      "La Ligue canadienne de hockey est la plus grande ligue de hockey de développement au monde. 52 équipes canadiennes et huit équipes américaines sont membres de la Ligue de hockey de l’Ontario, de la Ligue de hockey junior majeur du Québec et de la Ligue de hockey de l’Ouest. Les joueurs de la LCH obtiennent leur diplôme de fin d’études secondaires à un rythme supérieur à la moyenne nationale canadienne. La saison dernière, plus de neuf millions de partisans ont assisté aux matchs de la LCH pendant la saison régulière, les séries éliminatoires et la coupe Memorial présentée par Kia. La LCH fournit plus de joueurs à la Ligue nationale de hockey et à U SPORTS que toute autre ligue.",
    pp: "Politique de confidentialité",
    tc: "Conditions d’utilisation",

    support: "Soutien de CHL TV",
    supportText:
      "Pour toute information et pour accéder à la foire aux questions, veuillez consulter notre centre d’aide à la page:",

    selectLeague: "SÉLECTIONNEZ VOTRE LIGUE",
    audioTrack: "Piste Audio:",
  },
};

export default translations;
